<template>
  <base-form-item :field="field">
    <div>
      <ul
          class="el-form--todoList"
          v-if="data.checklist && data.checklist.length > 0"
      >
        <li
            :key="item.id"
            class="my-1"
            v-for="item in data.checklist"
        >
          <div class="d-flex justify-content-between align-items-center">
            <el-checkbox
                :value="!!+item.done"
                class="d-flex align-items-center w-100 checkbox--custom"
            >
              <span
                  :class="!!+item.done ? 'strike' : ''"
                  style="word-break: break-word; white-space: initial;"
              >{{ item.subject }}</span>
            </el-checkbox>
          </div>
        </li>
      </ul>
      <template v-else>
        <span class="no-date-title">{{ $t('system.no_data') }}</span>
      </template>
    </div>
  </base-form-item>
</template>

<script>
import axios from 'axios';
import BaseFormItem from '../../UI/FormItem.vue';
import notification from '../../../../../notification/notify';
import abstractForm from '../../mixin/index';

export default {
  mixins: [abstractForm],
  name: 'index',
  components: {
    BaseFormItem,
  },
  data() {
    return {};
  },
  methods: {
    changeItem(item) {
      let vm = this;
      let data = {
        todoID: item.id,
        issueID: vm.$route.params.issueId,
      };
      item.done = !+item.done;
      axios.post(`issue/${vm.$route.params.issueId}/changeStatus`, {data})
          .then(function (response) {
            notification.notify(
                vm.$t('notify.success'),
                response.data.message,
                'success');
          })
          .catch((error) => {
            item.done = !+item.done;
            console.log(error);
          });
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
>
/*.el-form--checklist{*/
.el-form--todoList {
  label {
    margin: 0;
  }

  .checkbox--custom {
    cursor: default;

    .el-checkbox__input, .el-checkbox__label {
      cursor: default;
    }
  }
}

/*}*/
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
/*.activities {*/
/*    line-height: initial;*/
/*    width: 100%;*/
/*    display: inline-block;*/
/*}*/
.el-form--todoList {
  margin: 0;
  list-style-type: none;
  padding: 0;

  li {
    @keyframes strike {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }

    .strike {
      position: relative;
    }

    .strike::after {
      content: ' ';
      position: absolute;
      top: 55%;
      left: 0;
      width: 100%;
      height: 1px;
      background: black;
      animation-name: strike;
      animation-duration: .3s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }
}
</style>
